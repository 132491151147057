<mat-toolbar class="header">
  <h1>Login</h1>
</mat-toolbar>
<body>
  <div class="container">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-xl-6 col-xs-12">
        <h1 id="titolo">Inserisci le tue credenziali</h1>
      </div>
      <div class="col-3"></div>
    </div>
  </div>

<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-6">
<mat-card>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <!--<form (submit)="onLogin(loginForm)" #loginForm="ngForm" *ngIf="!isLoading">-->
  <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm)" *ngIf="!isLoading">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Email</mat-label>
    <input matInput formControlName="email" [errorStateMatcher]="matcher"
      placeholder="Ex. pat@example.com" type="email">
    <mat-error *ngIf="loginForm.get('email').errors?.required">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="loginForm.get('email').errors?.required">
      Email is <strong>required</strong>
    </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" [errorStateMatcher]="matcher"
      placeholder="Ex. super-strong_password" [type]="hide ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    <!--<mat-error *ngIf="firstFormGroup.get('password').errors['email'] && !firstFormGroup.get('password').errors?.required">
      Please enter a valid password
    </mat-error>-->
    <mat-error *ngIf="loginForm.get('password').errors?.required">
      Password is <strong>required</strong>
    </mat-error>
    <mat-hint align="end">{{loginForm.get('password').value?.length}} / 8</mat-hint>
    </mat-form-field>
    <!--<mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput name="email" ngModel type="email" placeholder="Email" #emailInput="ngModel" required email>
      <mat-error *ngIf="emailInput.invalid">Please valid mail</mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Password</mat-label>
      <input type="password" name="passworg" ngModel matInput placeholder="Password" #passwordInput="ngModel" required>
      <mat-error *ngIf="passwordInput.invalid">Please valid password</mat-error>
    </mat-form-field>-->
    <button mat-raised-button color="primary" type="submit" *ngIf="!isLoading" [disabled]="!loginForm.valid">Login</button>
  </form>
</mat-card>
</div>
<div class="col-3"></div>
</div>
</div>
</body>
