import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-progetti',
  templateUrl: './progetti.component.html',
  styleUrls: ['./progetti.component.css']
})
export class ProgettiComponent implements OnInit {
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  progetti = [
    {
      nome: '2+2=0',
      url: 'https://www.2piu2uguale0.ch',
      immagini: [
      '2piu2_1.png',
      '2piu2_2.png',
      '2piu2_3.png',
      '2piu2_4.png',
      '2piu2_5.png',
      '2piu2_6.png'
    ],
    col: 'col-2',
    wid: '16.65%', //100%/#foto
    wid1: '50%',
    wid2: '10%'
    },
    {
      nome: 'DEE Natural',
      url: 'https://www.deenatural.ch',
      immagini: [
      'dee_1.png',
      'dee_2.png',
      'dee_3.png',
      'dee_4.png',
      'dee_5.png'
    ],
    col: 'col-2',
    wid: '20%', //100%/#foto
    wid1: '50%',
    wid2: '10%'
    },
    /*{
      nome: 'Bruce Arts',
      url: 'https://www.bruce-arts.ch',
      immagini: [
      '../../assets/dee_1.png',
      '../../assets/dee_2.png',
      '../../assets/dee_3.png',
      '../../assets/dee_4.png',
      '../../assets/dee_5.png'
    ],
    col: 'col-2',
    wid: '20%', //100%/#foto
    wid1: '50%',
    wid2: '10%'
    }*/
  ];
  idx: number;

  larghezza = '100%';
  panelOpenState = false;

  showNavigationArrows = true;
  showNavigationIndicators = true;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  images = [
    '../../assets/2piu2_1.png',
    '../../assets/2piu2_2.png',
    '../../assets/2piu2_3.png',
    '../../assets/2piu2_4.png',
    '../../assets/2piu2_5.png',
    '../../assets/2piu2_6.png'
  ];

  constructor() { }

  ngOnInit() {
  }



  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}
