<mat-toolbar class="footer" *ngIf="!(isHandset$ | async)">
  <!--<i class="fas fa-hand-spock fa-5x"></i>--><img src="https://firebasestorage.googleapis.com/v0/b/personale-f2c82.appspot.com/o/shaka.png?alt=media&token=cf753fc8-897a-4a85-b93b-c3117f54801a" alt=""> Aloha Web Design
  <span class="spacer"></span>
  <i class="material-icons md-48">contact_mail</i><a href="mailto:alohawebdesign2020@gmail.com"> alohawebdesign2020@gmail.com</a>
</mat-toolbar>

<mat-toolbar class="footer" *ngIf="(isHandset$ | async)">
  <div class="row">
    <div class="col-12">
      <img src="https://firebasestorage.googleapis.com/v0/b/personale-f2c82.appspot.com/o/shaka.png?alt=media&token=cf753fc8-897a-4a85-b93b-c3117f54801a" alt=""> Aloha Web Design
    </div>
    <div class="col-12">
      <i class="material-icons md-48">contact_mail</i><a href="mailto:alohawebdesign2020@gmail.com"> alohawebdesign2020@gmail.com</a>
    </div>
  </div>
</mat-toolbar>
