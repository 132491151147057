<mat-card>
  <!--<mat-spinner *ngIf="isLoading"></mat-spinner>-->
  <form [formGroup]="form" (submit)="onSavePost()"> <!--*ngIf="!isLoading">-->
    <mat-form-field>
      <mat-label>Titolo</mat-label>
      <input matInput type="text" formControlName="title" placeholder="Title">
      <mat-error *ngIf="form.get('title').invalid">Please enter a valid title</mat-error>
    </mat-form-field>
    <div>
      <div class="row">
        <button mat-stroked-button type="button" (click)="filePicker.click()" id="immagine">Pick Image</button>
      </div>
      <div class="row">
        <input type="file" #filePicker (change)="onImagePicked($event)">
      </div>
    </div>
    <div class="image-preview" *ngIf="imagePreview !== '' && imagePreview && form.get('image').valid">
      <img [src]="imagePreview" [alt]="form.value.title">
    </div>
    <mat-form-field>
      <mat-label>Descrizione</mat-label>
      <textarea matInput rows="4" name="content" formControlName="content" placeholder="Description"></textarea>
      <mat-error *ngIf="form.get('content').invalid">Please valid description</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Descrizione del prezzo</mat-label>
      <input matInput type="text" formControlName="descrPrezzo" placeholder="Esempio: CHF/kg">
      <mat-error *ngIf="form.get('descrPrezzo').invalid">Please valid title</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Prezzo</mat-label>
      <input matInput type="text" formControlName="cash" placeholder="Esempio: 20">
      <mat-error *ngIf="form.get('cash').invalid">Please valid title</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Save product</button>
  </form>
</mat-card>
