import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgettiComponent } from './progetti/progetti.component';
import { ContattiComponent } from './contatti/contatti.component';
import { ChiComponent } from './chi/chi.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WaveComponent } from './wave/wave.component';
//import { TypingAnimationDirective } from 'angular-typing-animation';
import { ProdCreateComponent } from './prod-create/prod-create.component';
import { ProdListComponent } from './prod-list/prod-list.component';

import { AuthInterceptor } from './auth/auth-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { PostsModule } from './prod-create/posts.module';
import { ErrorInterceptor } from './error-interceptor';
import { ErrorComponent } from './error/error.component';
import { AreaRiservataComponent, DialogAddProduct } from './area-riservata/area-riservata.component';
import { CommonModule } from '@angular/common';
import { GestureConfig } from "../gesture-config";
import { AuthModule } from './auth/auth.module';
import { TypeanimationComponent } from './typeanimation/typeanimation.component';
import { SharedModule } from './Shared/shared.module';
//import { RellaxDirective } from 'ng-rellax';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    //LoginComponent,
    //SignupComponent,
    ProgettiComponent,
    ContattiComponent,
    ChiComponent,
    WaveComponent,
    ProdCreateComponent,
    ProdListComponent,
    ErrorComponent,
    AreaRiservataComponent,
    //RellaxDirective,
    DialogAddProduct,
    TypeanimationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //AngularMaterialModule
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
    MatGridListModule,
    LayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatStepperModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule,
    NgbModule,
    HttpClientModule,
    PostsModule,
    MatIconModule,
    CommonModule,
    //TypingAnimationDirective,
    HammerModule,
    AuthModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [ErrorComponent, DialogAddProduct]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
