import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack',
  template: `<div><i class="fas fa-check-circle"></i><span>Grazie per averci contattato, la contatteremo presto</span></div>`,
  styleUrls: ['./snack.component.scss']
})
export class SnackComponent implements OnInit {

  constructor(@Inject(MatSnackBar) public data: any) { }

  ngOnInit() {
  }

}
