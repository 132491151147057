import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-area-riservata',
  templateUrl: './area-riservata.component.html',
  styleUrls: ['./area-riservata.component.css']
})
export class AreaRiservataComponent implements OnInit {

  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(DialogAddProduct);
  }

  ngOnInit() {
  }

}
@Component({
  selector: 'dialog-add-products',
  templateUrl: 'dialog-add-product.html',
})
export class DialogAddProduct {}
