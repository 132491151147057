import { AfterContentInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import ScrollOut from 'scroll-out';



@Component({
  selector: 'app-chi',
  templateUrl: './chi.component.html',
  styleUrls: ['./chi.component.css']
})
export class ChiComponent implements OnInit, AfterContentInit, OnDestroy {
  so: any;
  start1: boolean = false;
  start2: boolean = false;
  start3: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private el: ElementRef) {
  }


  ngOnInit () {
    // Start after 1 second + 1 second of startDelay
    setTimeout(() => this.start1 = true, 1000)
  }
  onTypingAnimationComplete1 () {
    setTimeout(() => this.start1 = false, 1000)
    setTimeout(() => this.start2 = true, 1000)
  }
  onTypingAnimationComplete2 () {
    setTimeout(() => this.start2 = false, 1000)
    setTimeout(() => this.start3 = true, 1000)
  }
  onTypingAnimationComplete3 () {
    setTimeout(() => this.start3 = false, 1000)
    setTimeout(() => this.start1 = true, 1000)
  }

  ngAfterContentInit() {
    this.so = ScrollOut({
      scope: this.el.nativeElement
    });
  }

  ngOnDestroy() {
    this.so.teardown();
  }

}
