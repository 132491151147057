import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';


import { SnackComponent } from '../snack/snack.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  isLoading = false;
  private authStatusSub: Subscription;
  selected1 = false;
  selected2 = false;
  selectedAbo: string;

  submitted = false;


  matcher = new MyErrorStateMatcher();
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  /*nameFormControl: FormControl;
  cogFormControl: FormControl;
  emailFormControl: FormControl;
  passFormControl: FormControl;*/
  hide = true;
  abos: string[] = ['Hoomaka', 'Akahai', 'Pono nui', 'Olu Olu', 'Aloha', 'Nessun Abbonamento'];


  constructor(private _formBuilder: FormBuilder, private breakpointObserver: BreakpointObserver, public authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit() {
    this.selectedAbo = 'vuoto';
    this.firstFormGroup = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      nome: new FormControl('', [
        Validators.required,
      ]),
      'cognome': new FormControl('', [
        Validators.required,
      ]),
      'password': new FormControl('', [
        //Validators.required,
        //Validators.minLength(8)
      ])
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    /*this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {
        this.isLoading = false;
      });*/
  }

  onSignup(form) {
    if (form.invalid) {
      return;
    }
    this.isLoading = true;
    this.selected1 = true;
    //this.authService.createUser(form.value.email, form.value.password);
    //console.log(form.value.password);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

  get firstFormControls(): any {
    return this.firstFormGroup['controls'];
 }

 onAbo() {
  this.selected2 = true;
 }

 onSend() {
  const nome = this.firstFormGroup.value.nome;
  const cognome = this.firstFormGroup.value.cognome;
  const email = this.firstFormGroup.value.email;
  const abo = this.selectedAbo;
  this.authService.sendMail(email, nome, cognome);
  const dati = [nome, cognome, email, abo];
  console.log(dati)
  //this.authService.sendMail(dati);
  this.firstFormGroup.reset();
  this.secondFormGroup.reset();
  this.submitted = true;
  this.snackBar.openFromComponent(SnackComponent, {
    data: {
      message: 'Hello, snackbar!'
    },
    duration: 2000,
    panelClass: ['snackbar']
  });
  this.router.navigate(['/ThankYou']);
}

}
