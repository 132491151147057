import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthData } from '../auth-data.model';
import { Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoading = false;
  matcher = new MyErrorStateMatcher();
  private authStatusSub: Subscription;
  loginForm: FormGroup;
  hide = true;

  auth!: AuthData;

  constructor(private _formBuilder: FormBuilder, public authService: AuthService, private cookieService: CookieService, private router: Router) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      'password': new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ])
    });
    /*this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
    authStatus => {
      this.isLoading = false;
    });*/
    const myToken = this.cookieService.get("2piu2-token");
      if (myToken) {
        this.auth = {username: "", password: ""};
        //this.router.navigate(['/Home'])
      } else {
          this.auth = {username: "", password: ""};
      }
  }

  onLogin(username: string, password: string) {
    this.auth.username = username;
    this.auth.password = password;
    /*this.authService.loginUser(this.auth).subscribe(
      (results: any) => {
        console.log(results);
        this.cookieService.set("2piu2-token", results.token);
        this.router.navigate(['/Home']);

      },
      err => console.log(err)
    );*/
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }

  get firstFormControls(): any {
    return this.loginForm['controls'];
 }
}
