import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ProgettiComponent } from './progetti/progetti.component';
import { ContattiComponent } from './contatti/contatti.component';
import { ChiComponent } from './chi/chi.component';
import { AreaRiservataComponent } from './area-riservata/area-riservata.component';
import { AuthGuard } from './auth/auth.guard';
import { ProdCreateComponent } from './prod-create/prod-create.component';
import { ThanksComponent } from './auth/thanks/thanks.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'Contatti', component: SignupComponent },
  { path: 'Progetti', component: ProgettiComponent },
  { path: 'Abbonamenti', component: ContattiComponent },
  { path: 'AboutUs', component: ChiComponent },
  { path: 'ThankYou', component: ThanksComponent },
  { path: 'Admin', component: AreaRiservataComponent, canActivate: [AuthGuard] },
  { path: 'edit/:postId', component: ProdCreateComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
