<body>
  <h1>Grazie per averci contattato</h1>
  <br>
  <h4>Ti risponderemo appena possibile</h4>
  <br>
  <br>
  <button mat-raised-button color="primary" routerLink="/">Torna alla Home</button>
</body>
<!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#158496" fill-opacity="1" d="M0,256L40,261.3C80,267,160,277,240,250.7C320,224,400,160,480,160C560,160,640,224,720,245.3C800,267,880,245,960,218.7C1040,192,1120,160,1200,144C1280,128,1360,128,1400,128L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>-->

<app-footer></app-footer>
