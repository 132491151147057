import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AngularMaterialModule } from '../angular-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { SignupComponent } from './signup/signup.component';
import { SnackComponent } from './snack/snack.component';
import { ThanksComponent } from './thanks/thanks.component';
import { SharedModule } from '../Shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    SnackComponent,
    ThanksComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  entryComponents: [SnackComponent]
})
export class AuthModule {}
