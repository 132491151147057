<mat-toolbar class="header">
  <h1>Signup</h1>
</mat-toolbar>
<body>
  <h1 id="titolo">Modulo di iscrizione</h1>
  <p>Riempi il modulo di iscrizione sottostante inserendo le tue credenziali e il tipo di abbonamento
    che desideri stipulare e successivamente ti contatteremo per discutere dei dettagli del sito
  </p>
  <p>L'inserimento di una password è necessaria per la creazione di un utente, e quindi di un'area riservata,
    per avere la possibilità di modificare il tuo sito.<br>
    Se non ti interessa questa feature basterà semplicemente ignorarla
  </p>




  <mat-horizontal-stepper [linear]="isLinear" #stepper [hidden]="submitted">
    <mat-step [stepControl]="firstFormGroup">
      <!--Primo step-->
      <form [formGroup]="firstFormGroup" (ngSubmit)="onSignup(firstFormGroup)">
        <ng-template matStepLabel>I tuoi dati personali</ng-template>
        <div class="container">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
              <div class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome"
                  placeholder="Ex. John">
                <mat-error *ngIf="firstFormControls.nome.errors?.required">
                  Name is <strong>required</strong>
                </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Cognome</mat-label>
                <input matInput formControlName="cognome" [errorStateMatcher]="matcher"
                  placeholder="Ex. Smith">
                <mat-error *ngIf="firstFormGroup.get('cognome').errors?.required">
                  Surname is <strong>required</strong>
                </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Email</mat-label>
                <input matInput formControlName="email" [errorStateMatcher]="matcher"
                  placeholder="Ex. pat@example.com" type="email">
                <mat-error *ngIf="firstFormGroup.get('email').errors?.required">
                  Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="firstFormGroup.get('email').errors?.required">
                  Email is <strong>required</strong>
                </mat-error>
                </mat-form-field>

                <!--<mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" [errorStateMatcher]="matcher"
                  placeholder="Ex. super-strong_password" [type]="hide ? 'password' : 'text'">
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>-->
                <!--<mat-error *ngIf="firstFormGroup.get('password').errors['email'] && !firstFormGroup.get('password').errors?.required">
                  Please enter a valid password
                </mat-error>-->
                <!--<mat-error *ngIf="firstFormGroup.get('password').errors?.required">
                  Password is <strong>required</strong>
                </mat-error>
                <mat-hint align="end">{{firstFormGroup.get('password').value?.length}} / 8</mat-hint>
                </mat-form-field>-->
              </div>
            </div>
            <div class="col-3"></div>
          </div>
        </div>


        <div class="row">
          <span class="spacer"></span>
          <button mat-raised-button matStepperNext type="submit" [disabled]="!firstFormGroup.valid">Next</button>
        </div>
      </form>
    </mat-step>

    <!--Seconda pagina-->

    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Scegli il tuo abbonamento</ng-template>
        <!--<mat-form-field>-->
          <div class="row" *ngIf="!(isHandset$ | async)">
            <div class="col-2"></div>
            <div class="col-5">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                color="primary"
                class="example-radio-group"
                [(ngModel)]="selectedAbo" [ngModelOptions]="{standalone: true}">
                <mat-radio-button class="example-radio-button" *ngFor="let abo of abos" [value]="abo">
                  {{abo}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-4">
              <div class="card" *ngIf="selectedAbo === 'Hoomaka'">
                <div class="card-header"><h5>Ho'omaka</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Akahai'">
                <div class="card-header"><h5>Akahai</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Pono nui'">
                <div class="card-header"><h5>Pono nui</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta al mese</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Olu Olu'">
                <div class="card-header"><h5>'Olu'olu</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta a settimana</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Aloha'">
                <div class="card-header"><h5>Aloha</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta a settimana con rapporto personalizzato</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Nessun Abbonamento'">
                <div class="card-header"><h5>Nessun Abbonamento</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Nessuna modifica del sito</td>
                      </tr>
                      <tr>
                        <td>No hosting</td>
                      </tr>
                      <tr>
                        <td>No Google analytics</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">0.-/mese, solo prezzo concordato per la realizzazione del sito</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1"></div>
          </div>


          <div class="row" *ngIf="(isHandset$ | async)">
            <div class="col-12">
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                color="primary"
                class="example-radio-group"
                [(ngModel)]="selectedAbo" [ngModelOptions]="{standalone: true}" id="colofono">
                <mat-radio-button class="example-radio-button" *ngFor="let abo of abos" [value]="abo">
                  {{abo}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row" *ngIf="(isHandset$ | async)">
            <div class="col-12">
              <div class="card" *ngIf="selectedAbo === 'Hoomaka'">
                <div class="card-header"><h5>Ho'omaka</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Akahai'">
                <div class="card-header"><h5>Akahai</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Pono nui'">
                <div class="card-header"><h5>Pono nui</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta al mese</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Olu Olu'">
                <div class="card-header"><h5>'Olu'olu</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta a settimana</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Aloha'">
                <div class="card-header"><h5>Aloha</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Modifica Sito</td>
                      </tr>
                      <tr>
                        <td>Hosting Sito</td>
                      </tr>
                      <tr>
                        <td>Google Analytics 1 volta a settimana con rapporto personalizzato</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">20.-/mese</small>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="selectedAbo === 'Nessun Abbonamento'">
                <div class="card-header"><h5>Nessun Abbonamento</h5></div>
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Nessuna modifica del sito</td>
                      </tr>
                      <tr>
                        <td>No hosting</td>
                      </tr>
                      <tr>
                        <td>No Google analytics</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-footer">
                    <small class="text-muted">0.-/mese, solo prezzo concordato per la realizzazione del sito</small>
                  </div>
                </div>
              </div>
            </div>
          </div>


        <!--</mat-form-field>-->


        <div class="row">
          <button mat-raised-button matStepperPrevious>Back</button>
          <span class="spacer"></span>
          <button mat-raised-button matStepperNext (click)="onAbo()" [disabled]="this.selectedAbo === 'vuoto'">Next</button>
        </div>
      </form>
    </mat-step>

    <!--Terza pagina-->
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="row">
        <span class="spacer"></span>
        <p *ngIf="this.selected1 && this.selected2">You are now done.</p>
        <span class="spacer"></span>
      </div>
      <div class="row">
        <span class="spacer"></span>
        <p *ngIf="!this.selected1" style="color: red;">Looks like you didn't select everything. Go check the first step</p>
        <span class="spacer"></span>
      </div>
      <div class="row">
        <span class="spacer"></span>
        <p *ngIf="!this.selected2" style="color: red;">Looks like you didn't select everything. Go check the first step</p>
        <span class="spacer"></span>
      </div>
      <div class="row">
        <button mat-raised-button matStepperPrevious>Back</button>
      </div>
      <div class="row">
        <span class="spacer"></span>
        <button mat-raised-button color="warn" (click)="stepper.reset()">Reset</button>
        <button mat-raised-button color="primary" style="margin-left: 2%;" (click)="onSend()" [disabled]="!(this.selected1 && this.selected2)">Invia</button>
        <span class="spacer"></span>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

</body>
<app-footer></app-footer>
