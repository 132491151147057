import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/mail/';

@Injectable({ providedIn: 'root'})
export class MailService {

  constructor(private http: HttpClient, private router: Router) {}

  sendMail() {
    const content = {};
    this.http.post(BACKEND_URL, content)
    .subscribe(
      data => {
          console.log("POST Request is successful ", data);
      },
      error => {
          console.log("Error", error);
      }
    );
    console.log('Funziona');
    //.subscribe(() => {});
  }
}
