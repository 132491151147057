import { NgModule } from '@angular/core';
import { ProdCreateComponent } from './prod-create.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MailComponent } from '../mail/mail.component';
import { MailService } from '../mail/mail.service';
import { ProdListComponent } from '../prod-list/prod-list.component';

@NgModule({
  declarations: [
    //ProdCreateComponent,
    //ProdListComponent,
    MailComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    RouterModule
  ],
  providers: [MailService]
})

export class PostsModule {}
