<mat-spinner *ngIf="isLoading"></mat-spinner>
<mat-accordion multi="true" *ngIf="posts.length > 0 && !isLoading">
  <mat-expansion-panel *ngFor="let post of posts">
    <mat-expansion-panel-header>{{ post.title }}</mat-expansion-panel-header>
    <div class="post-image">
      <img [src]="post.imagePath" [alt]="post.title">
    </div>
    <p>{{ post.content }}</p>
    <p>{{ post.descrPrezzo }}</p>
    <p>{{ post.cash }}</p>
    <mat-action-row *ngIf="userIsAuthenticated && userId === post.creator">
      <a mat-button [routerLink]="['/edit', post.id]" color="primary">EDIT</a>
      <button mat-button color="warn" (click)="onDelete(post.id)">DELETE</button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
<mat-paginator [length]="totalPosts" [pageSize]="postsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)" *ngIf="posts.length > 0"></mat-paginator>
<p class="info-text mat-body-1" *ngIf="posts.length <= 0 && !isLoading">No products added yet</p>
