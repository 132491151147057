<mat-toolbar class="header">
  <h1>I nostri abbonamenti</h1>
</mat-toolbar>

<body>

  <h4>Oltre alla realizzazione del vostro sito internet offriamo
     la possibilità di sottoscrivere svariati abbonamenti con molteplici benefici</h4>
     <hr>
     <br>
     <br>
     <br>
    <div class="row">
     <mat-slide-toggle
          class="example-margin"
          [color]="color"
          [(ngModel)]="isChecked">
      </mat-slide-toggle>
      <p *ngIf="isChecked">Fatturato annualmente</p><p *ngIf="!isChecked">Fatturato mensilmente</p>
    </div>

  <div class="card-group" *ngIf="!(isHandset$ | async)">
    <div class="card" style="height: 359px; box-shadow: none;">
      <div class="card-header" style="background-color: transparent;"><h5 style="color: #4051B5;">Caratteristiche</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
            <tr>
              <td>Hosting Sito</td>
            </tr>
            <tr>
              <td>Google Analytics</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Ho'omaka</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check" style="color: transparent;"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check" style="color: transparent;"></i></td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">15.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">12.75.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Akahai</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check" style="color: transparent;"></i></td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">20.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">17.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Pono nui</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>1 volta al mese</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">35.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">29.75.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>'Olu'olu</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>1 volta a settimana</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">40.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">34.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Aloha</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
              <td>1 volta a settimana, con rapporto personalizzato</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">50.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">42.5.-/mese</small>
        </div>
      </div>
    </div>
  </div>

  <div class="card-group" *ngIf="(isHandset$ | async)">
    <div class="card">
      <div class="card-header"><h5>Ho'omaka</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">15.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">12.75.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Akahai</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
            <tr>
              <td>Hosting Sito</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">20.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">17.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Pono nui</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
            <tr>
              <td>Hosting Sito</td>
            </tr>
            <tr>
              <td>Google Analytics 1 volta al mese</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">35.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">29.75.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>'Olu'olu</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
            <tr>
              <td>Hosting Sito</td>
            </tr>
            <tr>
              <td>Google Analytics 1 volta a settimana</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">40.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">34.-/mese</small>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header"><h5>Aloha</h5></div>
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr>
              <td>Modifica Sito</td>
            </tr>
            <tr>
              <td>Hosting Sito</td>
            </tr>
            <tr>
              <td>Google Analytics 1 volta a settimana con rapporto personalizzato</td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <small class="text-muted" *ngIf="!isChecked">50.-/mese</small>
          <small class="text-muted" *ngIf="isChecked">42.5.-/mese</small>
        </div>
      </div>
    </div>
  </div>


</body>
<app-footer></app-footer>
