<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" [ngClass]="{hidden: !(isHandset$ | async)}"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list *ngIf="(isHandset$ | async)">
      <a mat-button routerLink="/AboutUs" routerLinkActive="mat-accent">About Us</a>
      <a mat-button routerLink="/Progetti" routerLinkActive="mat-accent">Progetti realizzati</a>
      <a mat-button routerLink="/Abbonamenti" routerLinkActive="mat-accent">Abbonamenti</a>
      <!--<a mat-button routerLink="/Admin" routerLinkActive="mat-accent" *ngIf="userIsAuthenticated">Area Riservata</a>
      <a mat-button routerLink="/login" routerLinkActive="mat-accent" *ngIf="!userIsAuthenticated">Login</a>
      <button mat-button *ngIf="userIsAuthenticated" (click)="onLogout()">Logout</button>-->
      <a mat-button routerLink="/Contatti" routerLinkActive="mat-accent" *ngIf="!userIsAuthenticated">Contattaci</a>

      <!--<button mat-button [matMenuTriggerFor]="menu">Language<span class="material-icons">
        arrow_drop_down
        </span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="useLanguage('it')">Ita</button>
        <button mat-menu-item (click)="useLanguage('en')">Eng</button>
      </mat-menu>-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <img src="https://firebasestorage.googleapis.com/v0/b/personale-f2c82.appspot.com/o/shaka.png?alt=media&token=cf753fc8-897a-4a85-b93b-c3117f54801a" alt="">
      <!--<span><i class="fas fa-hand-spock"></i></span>-->
      <a mat-button routerLink="/" style="font-size: large;">Aloha Web Design</a>
      <mat-nav-list *ngIf="!(isHandset$ | async)">
        <a mat-button routerLink="/AboutUs" routerLinkActive="mat-accent">About Us</a>
        <a mat-button routerLink="/Progetti" routerLinkActive="mat-accent">Progetti realizzati</a>
        <a mat-button routerLink="/Abbonamenti" routerLinkActive="mat-accent">Abbonamenti</a>
      </mat-nav-list>
      <span class="spacer"></span>
      <mat-nav-list *ngIf="!(isHandset$ | async)">
        <!--<a mat-button routerLink="/Admin" routerLinkActive="mat-accent" *ngIf="userIsAuthenticated">Area Riservata</a>
        <a mat-button routerLink="/login" routerLinkActive="mat-accent" *ngIf="!userIsAuthenticated">Login</a>
        <button mat-button *ngIf="userIsAuthenticated" (click)="onLogout()">Logout</button>-->
        <a mat-button routerLink="/Contatti" routerLinkActive="mat-accent" *ngIf="!userIsAuthenticated">Contattaci</a>

        <!--<button mat-button [matMenuTriggerFor]="menu">Language<span class="material-icons">
          arrow_drop_down
          </span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="useLanguage('it')">Ita</button>
          <button mat-menu-item (click)="useLanguage('en')">Eng</button>
        </mat-menu>-->
      </mat-nav-list>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
        id="hamburger">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
