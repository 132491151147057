
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Post } from './post.model';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogAddProduct } from '../area-riservata/area-riservata.component';

const BACKEND_URL = environment.apiUrl + '/prods/';

@Injectable({ providedIn: 'root' })
export class PostsService {
  private posts: Post[] = [];
  private postsUpdated = new Subject<{ posts: Post[], postCount: number }>();

  constructor(private http: HttpClient, private router: Router, public dialogRef: MatDialogRef<DialogAddProduct>) {}

  getPosts(postsPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, posts: any, maxPosts: number }>(BACKEND_URL + queryParams)
      .pipe(map(postData => {
        return { posts: postData.posts.map(post => {
          return {
            title: post.title,
            content: post.content,
            id: post._id,
            imagePath: post.imagePath,
            descrPrezzo: post.descrPrezzo,
            cash: post.cash,
            creator: post.creator
          };
        }), maxPosts: postData.maxPosts};
      }))
      .subscribe(transformedPostData => {
        this.posts = transformedPostData.posts;
        this.postsUpdated.next({ posts: [...this.posts], postCount: transformedPostData.maxPosts });
      });
  }

  getPostUpdatedListener() {
    return this.postsUpdated.asObservable();
  }

  getPost(id: string) {
    return this.http.get<{ _id: string, title: string, content: string, imagePath: string, descrPrezzo: string, cash: number, creator: string
    }>(BACKEND_URL + id);
  }

  addPost(title: string, content: string, descrPrezzo: string, cash: any, image: File) {
    const postData = new FormData();
    postData.append('title', title);
    postData.append('content', content);
    postData.append('image', image);
    postData.append('descrPrezzo', descrPrezzo);
    postData.append('cash', cash);
    console.log('postData:', postData)
    this.http.post<{ message: string, post: Post }>(BACKEND_URL, postData)
      //{headers: new HttpHeaders({'Content-Type': 'multipart/form-data'})},
      //{observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(responseData => {
        this.dialogRef.close();
      });
  }

  updatePost(id: string, title: string, content: string, descrPrezzo: string, cash: any, image: File | string ) {
    let postData: Post | FormData;
    if (typeof image === 'object') {
      postData = new FormData();
      postData.append('id', id);
      postData.append('title', title);
      postData.append('content', content);
      postData.append('descrPrezzo', descrPrezzo);
      postData.append('cash', cash);
      postData.append('image', image, title);
    } else {
      postData = { id: id, title: title, content: content, descrPrezzo: descrPrezzo, cash: cash, imagePath: image, creator: null };
    }
    this.http.put(BACKEND_URL + id, postData)
      .subscribe(response => {
        this.dialogRef.close();
      });
  }

  deletePost(postId: string) {
    return this.http.delete(BACKEND_URL + postId);
  }

}
