import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave',
  templateUrl: './wave.component.html',
  styleUrls: ['./wave.component.css']
})
export class WaveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
