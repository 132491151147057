<mat-toolbar class="header">
  <mat-toolbar-row id="primo">
    <span class="spacer"></span><i class="fab fa-angular fa-7x"></i>
    <span class="spacer"></span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span class="spacer"></span>
    <i class="fas fa-server fa-7x" *ngIf="!(isHandset$ | async)"></i>
    <span class="spacer"></span>
    <h1 id="cima">Aloha Web Design</h1>
    <span class="spacer"></span>
    <i class="fas fa-database fa-7x" *ngIf="!(isHandset$ | async)"></i>
    <span class="spacer"></span>
  </mat-toolbar-row>
<mat-toolbar-row>
  <span class="spacer"></span>
  <h5>La soluzione semplice per il web design</h5>
  <span class="spacer"></span>
</mat-toolbar-row>
<mat-toolbar-row id="ultimo">
  <span class="spacer"></span>
  <i class="fab fa-node fa-7x"></i>
  <span class="spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>

<body>
  <h1>Portiamo online il tuo business locale</h1>
  <div class="container">
    <div class="row">
      <div class="col-md-6" id="local">
        <i class="fas fa-store fa-5x"></i>
        <i class="fas fa-long-arrow-alt-right fa-5x"></i>
        <i class="fas fa-globe fa-5x"></i>
      </div>
      <div class="col-md-6" id="local-descr">
        <h2>Aiutiamo a diventare un e-commerce di successo</h2>
        <p>Integriamo la vendita online con la vostra presenza fisica</p>
      </div>
    </div>
  </div>


  <h1>Siti web responsivi</h1>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h2>Adatti a tutti i devices</h2>
        <p>Ottmizzazione già in fase di produzione</p>
      </div>
      <div class="col-md-6"><img src="{{media}}" alt=""></div>
    </div>
  </div>

  <h1>Facili da gestire</h1>
  <p>Aggiungi rimuovi e modifica le foto della tua galleria o dei tuoi prodotti da remoto e autonomamente.
    Non è necessaria nessuna abilità di coding grazie al nostro innovativo sistema di gestione.
  </p>


  <div class="container">
    <div class="row">
      <div class="col-4"><img src="https://firebasestorage.googleapis.com/v0/b/personale-f2c82.appspot.com/o/shaka_blue.png?alt=media&token=3159b02a-5d37-4836-b6b1-908987d7d34b" id="spock"><!--<i class="fas fa-hand-spock fa-7x" id="spock"></i>--></div>
      <div class="col-4"></div>
      <div class="col-4"><i class="fas fa-database fa-7x" id="database"></i></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-2"><i class="fas fa-long-arrow-alt-right fa-7x fa-rotate-45" id="uno"></i></div>
      <div class="col-3"></div>
      <div class="col-2"><i class="fas fa-arrows-alt-v fa-7x fa-rotate-45"></i></div>
      <div class="col-3"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"><i class="fas fa-server fa-7x" id="server"></i></div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"><i class="fas fa-arrows-alt-v fa-7x" id="freccia-su"></i></div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"><i class="fab fa-angular fa-7x" id="angular"></i></div>
      <div class="col-4"></div>
    </div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col-md-6"><img src="{{statistic}}" alt="" id="statistics"></div>
      <div class="col-md-6">
        <h2>Analisi del tuo sito web</h2>
        <p>Approfitta del nostro pacchetto di gestione tramite Google Analytics per avere costantemente
          un'analisi personalizzata del tuo sito web.
        </p>
        <a mat-raised-button routerLink="/Abbonamenti" color="primary">Maggiori informazioni sui nostri abbonamenti</a>
      </div>
    </div>
  </div>
</body>
<app-footer></app-footer>
