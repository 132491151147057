<mat-toolbar class="header">
  <h1>Progetti realizzati</h1>
</mat-toolbar>

<body>
  <h3>Una selezione dei progetti realizzati per avere un'idea di come lavoriamo</h3>


  <mat-accordion>
    <mat-expansion-panel *ngFor="let prog of progetti" (opened)="panelOpenState = true"
    (closed)="panelOpenState = false" hideToggle>
      <mat-expansion-panel-header style="background-color: #4051B5;color:white">
        <mat-panel-title>
          <i class="fas fa-link"></i><a href="{{ prog.url }}" target="_blank">{{ prog.nome }}</a>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon *ngIf="!panelOpenState">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="panelOpenState">keyboard_arrow_up</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" (slide)="onSlide($event)">
        <ng-template ngbSlide *ngFor="let image of prog.immagini">
          <div class="picsum-img-wrapper">
            <img [src]="image">
          </div>
        </ng-template>
      </ngb-carousel>

    </mat-expansion-panel>
  </mat-accordion>
</body>
<app-footer></app-footer>


