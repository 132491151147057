import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { TranslationService } from '../shared/translation.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private AuthListenerSubs: Subscription

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private translation: TranslationService,
    private cookieService: CookieService) {}

  ngOnInit() {
    const myToken = this.cookieService.get("2piu2-token");
      if (myToken) {
        this.userIsAuthenticated = true;
        //this.router.navigate(['/Home'])
      } else {
        this.userIsAuthenticated = false;
      }
    /*this.userIsAuthenticated = this.authService.getIsAuth();
    this.AuthListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });*/
    console.log('Auth:', this.userIsAuthenticated);
  }

  onLogout() {
    //this.authService.logout();
    /*this.AuthListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });*/
  }

  ngOnDestroy() {
    this.AuthListenerSubs.unsubscribe();
  }

  useLanguage(lang: string) {
    this.translation.useLanguage(lang);
  }
}
