import {TranslateService} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class TranslationService {
  langChanged = new EventEmitter();

  private lang:string[] = [];
  //language = 'de';
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('it');
  }


  useLanguage(language: string) {
    this.translate.use(language);
    this.lang.push(language);
    this.langChanged.emit(this.lang.slice());
  }
  getLanguage() {
    return this.lang.pop();
  }


  public lat;
  public lng;


  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          console.log(this.lat);
          console.log(this.lat);
        }
      },
        (error: GeolocationPositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}
