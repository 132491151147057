<mat-toolbar class="header">
  <h1>Area Riservata</h1>
</mat-toolbar>

<h3>I tuoi prodotti</h3>
<app-prod-list></app-prod-list>

<div class="row">
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="openDialog()">Aggiungi un prodotto
    <span class="material-icons">
      queue
    </span>
  </button>
  <span class="spacer"></span>
</div>
