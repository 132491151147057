import { Component, OnInit } from '@angular/core';
import { MailService } from './mail.service';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit {

  constructor(private mailService: MailService) { }

  ngOnInit() {
  }

  onSend() {
    this.mailService.sendMail();
  }
}
