<mat-toolbar class="header">
  <h1>Chi Siamo</h1>
</mat-toolbar>

<body>

  <!--<h1 id="tit">Let the ocean worry about being blue</h1>
  <div class="row" *ngIf="(isHandset$ | async)">
    <div class="col-12">
      <div class="wave"></div>
    </div>-->

    <!--<div class="col-6">
      <div class="wave"></div>
    </div>-->
  <!--</div>

  <div class="row" *ngIf="!(isHandset$ | async)">
    <div class="wave2" style="width: 50%;"></div>
    <div class="wave2" style="width: 50%;"></div>
  </div>-->

  <app-wave></app-wave>
  <!--<audio src=""></audio>-->

</body>
<div class="bottom">
  <h2 id="titolo" class="animate__animated animate__jello animate__repeat-2">La nostra filosofia</h2>
  <!--<div data-scroll="out" class="row" id="filosofia">-->
  <div class="row" id="filosofia">
    <div class="col-md-6 col-sm-12">
      <img src="fatigue.png" alt="">
    </div>
    <div class="col-md-6 col-sm-12">
      <p>La vita è troppo complicata per occuparsi anche di:</p>
      <app-typeanimation></app-typeanimation>
      <!--<ul>
        <span typingAnimation [typeSpeed]="100" [condition]="start1" [startDelay]="1000" (complete)="onTypingAnimationComplete1()" *ngIf="start1" [hideCursorOnComplete]="true">- Realizzare il proprio sito</span>
        <span typingAnimation [typeSpeed]="100" [condition]="start2" [startDelay]="1000" (complete)="onTypingAnimationComplete2()" *ngIf="start2" [hideCursorOnComplete]="true">- Gestire il proprio sito</span>
        <span typingAnimation [typeSpeed]="100" [condition]="start3" [startDelay]="1000" (complete)="onTypingAnimationComplete3()" *ngIf="start3" [hideCursorOnComplete]="true">- Controllare le performance del proprio sito</span>
      </ul>-->
      <p>Lascia che ci occupiamo noi di tutto e concentrati solo sul tuo business</p>
    </div>
  </div>

  <div class="row" id="filosofia">
    <div class="col-sm-12 col-md-6">
      <i class="fas fa-leaf fa-7x"></i>
    </div>
    <div class="col-sm-12 col-md-6">
      <p>Si stima che il traffico web contribuisca circa del 1% alle emissioni globali di CO2<br> (circa quanto l'intero traffico aereo
        mondiale)
      </p>
      <p>Per questo motivo <span class="dotted" #tooltip="matTooltip"
        matTooltip="In un anno piantiamo alberi sufficienti a compensare quasi 1 milione di visite al mese dei nostri siti"
        matTooltipHideDelay="3000">compensiamo di oltre il <strong>400%</strong></span><br> le emissioni causate dai siti che approfittano del nostro
        abbonamento di hosting</p>
    </div>
  </div>
</div>
<div>
<app-footer></app-footer>
